import { CacheKeysModel } from 'utils/constants.types';


// eslint-disable-next-line import/prefer-default-export
export const CacheKeys: CacheKeysModel = {
  enableDarkMode: 'enable-dark-mode',
  orgId: 'org-id',
  profileSrc: 'profile-src',
  sessionId: 'session-id',
  response: 'response',
  token: 'token',
  userId: 'user-id',
  email: 'email',
  sessionToken: 'session-token',
  userResponse: 'user-response',
};

