export const auth = {
  landingPad: '/auth/landing-pad',
  login: '/auth/login',
  logout: '/auth/logout',
};

export const connections = {
  list: '/connections/list',
};

export const product = {
  details: '/product/:productId',
};

export const seller = {
  profile: '/seller/:sellerId',
};

export const user = {
  profile: '/user/profile',
};

export const chats = {
  base: '/chats',
};

export const homepage = '/welcome';
