// eslint-disable-next-line import/prefer-default-export
import { card } from 'styles/theme/card';
import { accordion } from 'styles/theme/accordion';
import { alert } from 'styles/theme/alert';
import { avatar } from 'styles/theme/avatar';
import { autocomplete } from 'styles/theme/autocomplete';
import { button } from 'styles/theme/button';
import { backdrop } from 'styles/theme/backdrop';
import { badge } from 'styles/theme/badge';
import { breadcrumbs } from 'styles/theme/breadcrumbs';
import { fab } from 'styles/theme/button-fab';
import { buttonGroup } from 'styles/theme/button-group';
import { toggleButton } from 'styles/theme/button-toggle';
import { checkbox } from 'styles/theme/checkbox';
import { chip } from 'styles/theme/chip';
import { dialog } from 'styles/theme/dialog';
import { drawer } from 'styles/theme/drawer';
import { form } from 'styles/theme/form';
import { link } from 'styles/theme/link';
import { tabs } from 'styles/theme/tabs';
import { menu } from 'styles/theme/menu';
import { list } from 'styles/theme/list';
import { stack } from 'styles/theme/stack';
import { paper } from 'styles/theme/paper';
import { table } from 'styles/theme/table';
import { radio } from 'styles/theme/radio';
import { appBar } from 'styles/theme/appbar';
import { slider } from 'styles/theme/slider';
import { rating } from 'styles/theme/rating';
import { select } from 'styles/theme/select';
import { stepper } from 'styles/theme/stepper';
import { tooltip } from 'styles/theme/tooltip';
import { popover } from 'styles/theme/popover';
import { svgIcon } from 'styles/theme/svg-icon';
import { skeleton } from 'styles/theme/skeleton';
import { progress } from 'styles/theme/progress';
import { switches } from 'styles/theme/switch';
import { textfield } from 'styles/theme/textfield';
import { typography } from 'styles/theme/typography';
import { pagination } from 'styles/theme/pagination';


// eslint-disable-next-line import/prefer-default-export
export const components = {
  ...fab,
  ...card,
  ...link,
  ...form,
  ...tabs,
  ...chip,
  ...menu,
  ...list,
  ...stack,
  ...paper,
  ...table,
  ...alert,
  ...badge,
  ...radio,
  ...dialog,
  ...appBar,
  ...avatar,
  ...drawer,
  ...slider,
  ...rating,
  ...select,
  ...button,
  ...stepper,
  ...tooltip,
  ...popover,
  ...svgIcon,
  ...skeleton,
  ...backdrop,
  ...progress,
  ...switches,
  ...checkbox,
  ...accordion,
  ...textfield,
  ...typography,
  ...pagination,
  ...breadcrumbs,
  ...buttonGroup,
  ...autocomplete,
  ...toggleButton,
};
