import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import * as spaUrls from 'utils/spaUrls';
import Badge from '@mui/material/Badge';
import SvgIcon from '@mui/material/SvgIcon';


export type MessageButtonProps = IconButtonProps;


export default function MessageButton({ sx }: MessageButtonProps) {
  const navigate = useNavigate();
  return (
    <IconButton
      aria-label='messages'
      sx={{
        p: 0, width: 40, height: 40, ...sx,
      }}
      onClick={() => navigate(spaUrls.chats.base)}
    >
      <Badge color='error' variant='dot'>
        <SvgIcon
          sx={{
            transition: 'transform 0.3s ease',
            '&:hover': { transform: 'scale(1.2)' },
          }}
        >
          <path
            fill='currentColor'
            d='M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1S1 5.925 1 12c0 1.76.413 3.423 1.148 4.898c.195.392.26.84.147 1.263l-.655 2.448a1.43 1.43 0 0 0 1.75 1.751l2.45-.655a1.8 1.8 0 0 1 1.262.147A10.96 10.96 0 0 0 12 23'
            opacity='0.5'
          />
          <path
            fill='currentColor'
            d='M10.9 12a1.1 1.1 0 1 0 2.2 0a1.1 1.1 0 0 0-2.2 0m-4.4 0a1.1 1.1 0 1 0 2.2 0a1.1 1.1 0 0 0-2.2 0m8.8 0a1.1 1.1 0 1 0 2.2 0a1.1 1.1 0 0 0-2.2 0'
          />
        </SvgIcon>
      </Badge>
    </IconButton>
  );
}
