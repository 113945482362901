import type { Theme, SxProps, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';

// ----------------------------------------------------------------------

export type LayoutSectionProps = {
    sx?: SxProps<Theme>;
    cssVars?: CSSObject;
    children?: React.ReactNode;
    footerSection?: React.ReactNode;
    headerSection?: React.ReactNode;
    sidebarSection?: React.ReactNode;
};

export const layoutClasses = {
  root: 'layout__root',
  main: 'layout__main',
  header: 'layout__header',
  content: 'layout__main__content',
  hasSidebar: 'layout__has__sidebar',
};

export function LayoutSection({
  sx,
  cssVars,
  children,
  footerSection,
  headerSection,
  sidebarSection,
}: LayoutSectionProps) {
  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        body: {
          '--layout-nav-zIndex': 1101,
          '--layout-nav-mobile-width': '320px',
          '--layout-header-blur': '8px',
          '--layout-header-zIndex': 1100,
          '--layout-header-mobile-height': '64px',
          '--layout-header-desktop-height': '72px',
          ...cssVars,
        },
      }}
    />
  );

  return (
    <>
      {inputGlobalStyles}

      <Box id='root__layout' className={layoutClasses.root} sx={sx}>
        {sidebarSection ? (
          <>
            {sidebarSection}
            <Box
              display='flex'
              flex='1 1 auto'
              flexDirection='column'
              className={layoutClasses.hasSidebar}
            >
              {headerSection}
              {children}
              {footerSection}
            </Box>
          </>
        ) : (
          <>
            {headerSection}
            {children}
            {footerSection}
          </>
        )}
      </Box>
    </>
  );
}
