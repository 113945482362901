import React, { useState } from 'react';
import {
  Box, Button, Card, CardContent, CardMedia, Typography,
} from '@mui/material';
import {
  IProduct, listerWishListQueryKey, productListQueryKey, useAddWatchlist,
} from 'pages/HomePage/queries';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { retrieve } from 'utils/cacheUtils';
import { CacheKeys } from 'utils/constants';
import { useQueryClient } from '@tanstack/react-query';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import userImage from 'assets/avatar-25.webp';
import LoadGoogleMaps from 'pages/UserProfile/components/LoadGoogleMaps';
import UpdateListingModal from 'pages/UserProfile/components/UpdateListingModal';
import { useNavigate } from 'react-router-dom';


export default function ProductCard({
  product,
  showEditButton = false,
}: { product: IProduct, showEditButton: boolean }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const buyerId = retrieve(CacheKeys.userId, { parseJson: false });
  const isLiked = product?.interested;
  const addWatchlistHook = useAddWatchlist(product?.id || '', buyerId);

  const handleLikeToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    addWatchlistHook.mutate(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: productListQueryKey(buyerId) });
        queryClient.invalidateQueries({ queryKey: listerWishListQueryKey(buyerId) });
      },
    });
  };

  const handleCardClick = () => {
    navigate(`/product/${product.id}`);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setModalOpen(true);
  };

  return (
    <>
      <Card
        data-testid='product-card-link'
        onClick={handleCardClick}
        sx={{
          position: 'relative',
          borderRadius: '8px',
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: 6,
          },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            padding: '8px',
            borderRadius: '8px',
            position: 'relative',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              height: '254px',
              width: '254px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <CardMedia
              component='img'
              image={product.images[0]}
              alt={product.name}
              sx={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
                borderRadius: '4px',
              }}
            />
          </Box>
          {showEditButton ? (
            <Button
              variant='contained'
              onClick={handleEditClick}
              startIcon={<EditIcon />}
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
              }}
            >
              Edit Listing
            </Button>
          ) : (
            <IconButton
              onClick={handleLikeToggle}
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                color: isLiked ? 'red' : '',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                },
              }}
            >
              {isLiked ? <Favorite /> : <FavoriteBorder />}
            </IconButton>
          )}
        </Box>
        <CardContent>
          <Tooltip title={product.name}>
            <Typography variant='subtitle2' gutterBottom>
              {product.name.length > 28 ? `${product.name.slice(0, 28)}...` : product.name}
            </Typography>
          </Tooltip>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Avatar
                alt={product.seller?.first_name}
                imgProps={{ referrerPolicy: 'no-referrer' }}
                src={product.seller?.profile_image_url || userImage}
              />
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' color='textPrimary'>
                  {`${product.seller?.first_name} ${product.seller?.last_name[0]}.` || ''}
                </Typography>
                <Tooltip title={product.location.address || ''}>
                  <Typography variant='caption'>
                    {product.location.address && product.location?.address?.length > 15
                      ? `${product.location.address?.slice(0, 15)}...`
                      : product.location.address || ''}
                  </Typography>
                </Tooltip>
              </Stack>
            </Stack>
            <Typography variant='subtitle1' color='textPrimary'>
              $
              {product.price % 1 === 0 ? product.price.toFixed(0) : product.price.toFixed(2)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      {modalOpen && (
      <LoadGoogleMaps>
        <UpdateListingModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          currentListing={product}
        />
      </LoadGoogleMaps>
      )}
    </>
  );
}
