const authentication = {
  StytchTokenType: 'stytch_token_type',
  StytchToken: 'token',
  Oauth: 'oauth',
  SessionDurationMinutes: 60,
  Microsoft: 'Microsoft',
  Linkedin: 'LinkedIn',
};

export default authentication;
